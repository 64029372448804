<template>
  <button class="button button--quick-shop" :class="{ 'has-price' : price }" @click="handleClick">
    <template v-if="price">
      <Price :price="price" :salePrice="salePrice" />
      <span>{{ text }}</span>
    </template>
    <template v-else>
      {{ text }}
      <SvgIcon name="chevron-right" />
    </template>
  </button>
</template>

<script>
  import { mapActions } from 'vuex';

  import Price from '@/components/shop/Price';
  import SvgIcon from '@/components/global/SvgIcon';

  export default {
    name: 'QuickShopButton',

    components: {
      Price,
      SvgIcon
    },

    props: {
      text: {
        type: String,
        default: 'Quick Shop'
      },
      type: {
        type: String,
        default: null
      },
      price: {
        type: [String, Number],
        default: null
      },
      salePrice: {
        type: [String, Number],
        default: null
      },
      activeProductUid: {
        type: String,
        default: null
      },
      activeProductVariantId: {
        type: String,
        default: null
      },
      modalTitle: {
        type: String,
        default: 'Quick Shop'
      },
      modalProducts: {
        type: Array,
        default: null
      }
    },

    methods: {
      ...mapActions([
        'openQuickShopModal'
      ]),
      handleClick() {
        const payload = {
          activeProductUid: this.activeProductUid,
          type: this.type,
          modalTitle: this.modalTitle,
          modalProducts: this.modalProducts,
          activeProductVariantId: this.activeProductVariantId
        };

        this.openQuickShopModal(payload);

        var event_page_type = '';
        if(window.location.href.includes('/shop/product')){
          event_page_type = 'product';
        }else if(window.location.href.includes('/shop')){
          event_page_type = 'collection';
        }else if(window.location.href.includes('/beauty-edit')){
          event_page_type = 'blog';
        }else{
          event_page_type = 'home';
        }

        this.$gtag.event('click', {
          event_category: "Quick Shop",
          event_label: event_page_type
        })

      }
    }
  }
</script>
