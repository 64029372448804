<template>
  <Modal type="back-in-stock">
    <template v-slot:title>
      <h2>Email When Available</h2>
    </template>
    <template v-slot:body>
      <p>
        Register your email address below to receive an email as soon as this
        becomes available again.
      </p>
      <form @submit.prevent="registerNotification" class="form">
        <div class="form__row">
          <div class="form__field" :class="{ 'has-error': $v.email.$error }">
            <label class="accessible" for="email">Email (Required)</label>
            <input
              id="email"
              name="email"
              type="text"
              placeholder="Email"
              v-model.trim="email"
            />
            <span class="error" v-if="$v.email.$error && !$v.email.required">
              This field is required
            </span>
            <span class="error" v-if="$v.email.$error && !$v.email.email">
              Please enter a valid email address
            </span>
          </div>
        </div>
        <div class="form__row form__row--button">
          <div class="form__field">
            <Button :text="buttonText" />
          </div>
        </div>
        <FormFeedback
          :showFeedback="showFeedback"
          :submitState="submitState"
          :submitMessage="submitMessage"
        />
      </form>
      <p class="small">
        We'll notify you when this product is {{ product.isComingSoon ? 'available' : 'back in stock' }}. We don't share your
        address with anybody else.
      </p>
    </template>
  </Modal>
</template>

<script>
  import axios from 'axios'
  import { mapState, mapGetters, mapActions } from 'vuex'

  import { required, email } from 'vuelidate/lib/validators'

  import formMixin from '@/mixins/formMixin'

  import Modal from '@/components/global/Modal'
  import Button from '@/components/global/Button'
  import FormFeedback from '@/components/global/FormFeedback'

  export default {
    name: 'BackInStockModal',

    mixins: [formMixin],

    components: {
      Modal,
      Button,
      FormFeedback,
    },

    data() {
      return {
        email: null,
        defaultButtonText: 'Email Me When Available',
      }
    },

    computed: {
      ...mapState({
        product: state => state.modal.backInStockProduct,
      }),
      ...mapGetters(['shopifyUrl']),
    },

    validations: {
      email: {
        required,
        email,
      },
    },

    methods: {
      ...mapActions(['closeModal']),

      cleanVariantId(variantId) {
        if (!variantId) return null;

        return variantId.replace('gid://shopify/ProductVariant/', '');
      },

      async registerNotification() {
        this.$v.$touch()
        this.showFeedback = false
        this.isSubmitting = true

        if (this.$v.$invalid) {
          this.handleFormFeedback('error')
        } else {
          try {
            const shop = this.shopifyUrl
            const productId = this.product.id
            const utcOffset = new Date().getTimezoneOffset()
            const variantId = this.cleanVariantId(this.product.variantId);

            const { data } = await axios.get(
              `https://app.backinstock.org/stock_notification/create.json?shop=${shop}&notification[email]=${this.email}&notification[product_no]=${productId}&notification[quantity_required]=1&notification[accepts_marketing]=true&notification[customer_utc_offset]=${utcOffset}&variant[variant_no]=${variantId}`
            )

            if (data.status == 'Error') {
              this.handleFormFeedback('error', data.errors.base[0])
            } else {
              this.handleFormFeedback(
                'success',
                `Your back in stock notification has been registered!`
              )

              setTimeout(() => {
                this.closeModal()
              }, 5000)
            }
          } catch (err) {
            console.log(err)
            this.handleFormFeedback('error', err)
          }
        }
      },
    },
  }
</script>
